import { ErrorCodes } from "atfcore-commonclasses";

/**
 * Configurazione del Router
 */

let firstPageVisited = true;
angular.module("app")
    .run(
        ["$rootScope", "$state", "$stateParams",
            ($rootScope: any, $state: any, $stateParams: any) => {
                $rootScope.$state = $state;
                $rootScope.$stateParams = $stateParams;
            }
        ]
    )
    .config(
        ["$stateProvider", "$urlRouterProvider",
            ($stateProvider: any, $urlRouterProvider: any) => {
                $urlRouterProvider
                    // Se nessuna pagina particolare viene richiesta, allora si va all'autenticazione
                    .otherwise("/app/localLogin");

                $stateProvider
                    .state("app", {
                        abstract: true,
                        url: "/app",
                        templateUrl: "app/shared/global/commonServices.html",
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/shared/notification/NotificationService.js',
                                        'js/shared/global/AnagService.js',
                                        'js/shared/global/CommonServicesController.js',
                                        'js/routes/home/SessionStorageService.js']);
                                }]
                        }
                    })

                    .state("app.access", {
                        abstract: true,
                        url: "/access",
                        template: "<div ui-view class=\"fade-in-right-big smooth\"></div>"
                    })

                    // State posto fra l'autenticazione e la prima pagina dell'applicativo
                    .state('app.intermediate', {
                        url: '/intermediate',
                        templateUrl: 'app/routes/structure/intermediate/intermediate.html',
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load(['js/routes/structure/intermediate/IntermediateController.js']);
                                }]
                        }
                    })

                    // Pagina per l'immissione della nuova password
                    .state("app.recoveryUserPassword", {
                        url: "/recoveryUserPassword/:userId/:token",
                        templateUrl: "app/routes/recoveryUserPassword/recoveryUserPassword.html",
                        resolve: {
                            deps: ["$ocLazyLoad",
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        "js/routes/recoveryUserPassword/RecoveryUserPasswordController.js"]);
                                }]
                        }
                    })

                    // Pagina di Login
                    .state("app.localLogin", {
                        url: "/localLogin",
                        templateUrl: "app/routes/login/login.html",
                        resolve: {
                            deps: ["$ocLazyLoad",
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        "js/routes/login/LoginController.js"]);
                                }]
                        }
                    })

                    // Pagina di Login SAML
                    .state("app.login", {
                        url: "/login",
                        templateUrl: "app/routes/login/ssoLogin.html",
                        resolve: {
                            deps: ["$ocLazyLoad",
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        "js/routes/login/SsoLoginController.js"]);
                                }]
                        }
                    })

                    // Pagina di Logout SAML
                    .state("app.logoutCompleted", {
                        url: "/logoutCompleted",
                        templateUrl: "app/routes/login/logoutCompleted.html"
                    })

                    // Pagina di Errore Login SAML
                    .state("app.samlError", {
                        url: "/samlError",
                        templateUrl: "app/routes/login/samlError.html",
                        resolve: {
                            deps: ["$ocLazyLoad",
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        "js/routes/login/SamlErrorController.js"]);
                                }]
                        }
                    })

                    // Pagina di errore, browser o device non supportati
                    .state("app.compatibilityError", {
                        url: "/compatibilityError",
                        templateUrl: "app/routes/compatibilityError/compatibilityError.html"
                    })


                    // State astratto che conterrà l'applicativo, l'header e la sidebar
                    .state('app.libraryApp', {
                        abstract: true,
                        url: '/library',
                        templateUrl: 'app/routes/structure/library/libraryApp.html',
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/itemDetail/ItemDetailService.js',
                                        'js/routes/structure/library/LibraryController.js',
                                        'js/routes/home/SessionStorageService.js']);
                                }]
                        }
                    })

                    // Definizione della pagina per ricercare con la ricerca degli oggetti. Le ricerche vengono salvate nel Session Storage, così da poterle recuperare
                    .state('app.libraryApp.home', {
                        url: '/home/:searchId',
                        views: {
                            "content": { templateUrl: 'app/routes/home/home.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load(['js/routes/home/HomeController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'LIBRARY_USE_ITEM',
                            forceAdminMode: false
                        }
                    })

                    // Definizione della pagina di home per gli amministratori (quindi con tre tab: contenuto - bozze - gruppi)
                    .state('app.libraryApp.homeAdmin', {
                        url: '/homeAdmin/:searchId',
                        views: {
                            "content": { templateUrl: 'app/routes/homeAdmin/homeAdmin.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/homeAdmin/SearchItemsInHomeAdminService.js',
                                        'js/routes/homeAdmin/TemplateService.js',
                                        'js/routes/home/SessionStorageService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/homeAdmin/HomeAdminController.js')
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'LIBRARY_ADMIN_MANAGE_ITEM',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina per scegliere quale tipo di Item si sta andando a creare
                    .state('app.libraryApp.chooseItem', {
                        url: '/chooseItem',
                        views: {
                            "content": { templateUrl: 'app/routes/chooseItem/chooseItem.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/chooseItem/ChooseItemController.js',
                                        'js/routes/home/SessionStorageService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'LIBRARY_ADMIN_MANAGE_ITEM',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina di creazione dell'oggetto Item
                    .state('app.libraryApp.createItem', {
                        url: '/createItem',
                        params: {
                            itemType: null // Tipo di oggetto che sto andando a creare
                        },
                        views: {
                            "content": { templateUrl: 'app/routes/createItem/createItem.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/createItem/CreateItemService.js',
                                        'js/routes/home/SessionStorageService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/createItem/CreateItemController.js')
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'LIBRARY_ADMIN_MANAGE_ITEM',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina di editing di un oggetto
                    .state('app.libraryApp.editItem', {
                        url: '/editItem/:itemId',
                        views: {
                            "content": { templateUrl: 'app/routes/editItem/editItem.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load(['js/shared/previewCard/previewCard.js',
                                        'js/shared/itemActionsCard/itemActionsCard.js',
                                        'js/shared/kalturaPlayer/kalturaPlayer.js',
                                        'js/shared/scormPlayer/scormPlayer.js',
                                        'js/routes/editItem/EditItemService.js',
                                        'js/routes/home/SessionStorageService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/editItem/EditItemController.js')
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'LIBRARY_ADMIN_MANAGE_ITEM',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina con il dettaglio di un oggetto non legato a nulla
                    .state('app.libraryApp.itemDetail', {
                        url: '/itemDetail/:itemId',
                        views: {
                            "content": { templateUrl: 'app/routes/itemDetail/itemDetail.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/shared/kalturaPlayer/kalturaPlayer.js',
                                        'js/shared/scormPlayer/scormPlayer.js',
                                        'js/routes/itemDetail/SearchItemsService.js',
                                        'js/routes/home/SessionStorageService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/itemDetail/ItemDetailController.js')
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'LIBRARY_USE_ITEM'
                        }
                    })

                    // Definizione della pagina con il dettaglio di un oggetto aggiunto ad un Learning Plan
                    .state('app.libraryApp.itemDetailLp', {
                        url: '/itemDetailLp/:lpId/:itemId',
                        views: {
                            "content": { templateUrl: 'app/routes/itemDetail/itemDetail.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/shared/kalturaPlayer/kalturaPlayer.js',
                                        'js/shared/scormPlayer/scormPlayer.js',
                                        'js/routes/itemDetail/SearchItemsService.js',
                                        'js/routes/home/SessionStorageService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/itemDetail/ItemDetailController.js')
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'LIBRARY_USE_ITEM'
                        }
                    })

                    // Definizione della pagina con il dettaglio di un oggetto aggiunto ad una sezione
                    .state('app.libraryApp.itemDetailSec', {
                        url: '/itemDetailSec/:projectId/:sectionId/:itemId',
                        views: {
                            "content": { templateUrl: 'app/routes/itemDetail/itemDetail.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/shared/kalturaPlayer/kalturaPlayer.js',
                                        'js/shared/scormPlayer/scormPlayer.js',
                                        'js/routes/itemDetail/SearchItemsService.js',
                                        'js/routes/home/SessionStorageService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/itemDetail/ItemDetailController.js')
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'LIBRARY_USE_ITEM'
                        }
                    })

                    // Definizione della pagina con il dettaglio di un oggetto aperto dall'edit di un learning plan
                    .state('app.libraryApp.itemDetailEditLp', {
                        url: '/itemDetailEditLp/:editingLpId/:itemId',
                        views: {
                            "content": { templateUrl: 'app/routes/itemDetail/itemDetail.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/shared/kalturaPlayer/kalturaPlayer.js',
                                        'js/shared/scormPlayer/scormPlayer.js',
                                        'js/routes/itemDetail/SearchItemsService.js',
                                        'js/routes/home/SessionStorageService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/itemDetail/ItemDetailController.js')
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'LIBRARY_USE_ITEM'
                        }
                    })

                    // Definizione della pagina con il dettaglio di un oggetto aperto dall'edit di una sezione
                    .state('app.libraryApp.itemDetailEditSection', {
                        url: '/itemDetailEditSection/:editingProjectId/:editingSectionId/:itemId',
                        views: {
                            "content": { templateUrl: 'app/routes/itemDetail/itemDetail.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/shared/kalturaPlayer/kalturaPlayer.js',
                                        'js/shared/scormPlayer/scormPlayer.js',
                                        'js/routes/itemDetail/SearchItemsService.js',
                                        'js/routes/home/SessionStorageService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/itemDetail/ItemDetailController.js')
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'LIBRARY_USE_ITEM'
                        }
                    })

                    // Definizione della pagina con il dettaglio di un oggetto aperto dall'edit di una sezione
                    .state('app.libraryApp.itemDetailToAddToSection', {
                        url: '/itemDetailToAddToSection/:editingProjectId/:editingSectionId/:itemToAddId',
                        views: {
                            "content": { templateUrl: 'app/routes/itemDetail/itemDetail.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/shared/kalturaPlayer/kalturaPlayer.js',
                                        'js/shared/scormPlayer/scormPlayer.js',
                                        'js/routes/itemDetail/SearchItemsService.js',
                                        'js/routes/home/SessionStorageService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/itemDetail/ItemDetailController.js')
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'LIBRARY_USE_ITEM'
                        }
                    })

                    // Definizione della pagina con il dettaglio di un oggetto aperto dall'edit di un Learning Plan
                    .state('app.libraryApp.itemDetailToAddToLearningPlan', {
                        url: '/itemDetailToAddToLearningPlan/:editingLearningPlanId/:itemToAddId/:isDraft',
                        views: {
                            "content": { templateUrl: 'app/routes/itemDetail/itemDetail.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/shared/kalturaPlayer/kalturaPlayer.js',
                                        'js/shared/scormPlayer/scormPlayer.js',
                                        'js/routes/itemDetail/SearchItemsService.js',
                                        'js/routes/home/SessionStorageService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/itemDetail/ItemDetailController.js')
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'LIBRARY_USE_ITEM'
                        }
                    })

                    // Definizione della pagina con il dettaglio di un progetto
                    .state('app.libraryApp.projectDetail', {
                        url: '/projectDetail/:itemId',
                        views: {
                            "content": { templateUrl: 'app/routes/projectDetail/projectDetail.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/projectDetail/ProjectDetailController.js',
                                        'js/routes/home/SessionStorageService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'LIBRARY_USE_ITEM'
                        }
                    })

                    // Definizione della pagina con il dettaglio di una sezione. Il parametro del progetto è opzionale; se presente, sarà inserito nei breadcrumb
                    .state('app.libraryApp.sectionDetail', {
                        url: '/sectionDetail/:projectId/:itemId',
                        views: {
                            "content": { templateUrl: 'app/routes/sectionDetail/sectionDetail.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/sectionDetail/SectionDetailController.js',
                                        'js/routes/sectionDetail/SectionDetailService.js',
                                        'js/routes/home/SessionStorageService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'LIBRARY_USE_ITEM'
                        }
                    })

                    // Definizione della pagina che modifica un Learning Plan
                    .state('app.libraryApp.editLearningPlan', {
                        url: '/editLearningPlan/:itemId',
                        views: {
                            "content": { templateUrl: 'app/routes/editLearningPlan/editLearningPlan.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load(['js/shared/previewCard/previewCard.js',
                                        'js/shared/itemActionsCard/itemActionsCard.js',
                                        'js/routes/editLearningPlan/EditLearningPlanService.js',
                                        'js/routes/home/SessionStorageService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/editLearningPlan/EditLearningPlanController.js')
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'LIBRARY_MANAGE_LEARNING_PLAN',
                            forceAdminMode: false
                        }
                    })

                    // Definizione della pagina che modifica un Learning Plan con diritti di amminisrtatore
                    .state('app.libraryApp.editLearningPlanAdmin', {
                        url: '/editLearningPlanAdmin/:itemId',
                        views: {
                            "content": { templateUrl: 'app/routes/editLearningPlanAdmin/editLearningPlanAdmin.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load(['js/shared/previewCard/previewCard.js',
                                        'js/shared/itemActionsCard/itemActionsCard.js',
                                        'js/routes/editLearningPlanAdmin/EditLearningPlanAdminService.js',
                                        'js/routes/home/SessionStorageService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/editLearningPlanAdmin/EditLearningPlanAdminController.js')
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'LIBRARY_ADMIN_MANAGE_LEARNING_PLAN',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina che modifica un Progetto
                    .state('app.libraryApp.editProject', {
                        url: '/editProject/:itemId',
                        views: {
                            "content": { templateUrl: 'app/routes/editProject/editProject.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load(['js/shared/previewCard/previewCard.js',
                                        'js/shared/itemActionsCard/itemActionsCard.js',
                                        'js/routes/editProject/EditProjectService.js',
                                        'js/routes/home/SessionStorageService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/editProject/EditProjectController.js')
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'LIBRARY_ADMIN_MANAGE_PROJECT',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina che crea una sezione da aggiungere a un Progetto
                    .state('app.libraryApp.newSection', {
                        url: '/newSection/:itemId',
                        views: {
                            "content": { templateUrl: 'app/routes/newSection/newSection.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/newSection/NewSectionService.js',
                                        'js/routes/home/SessionStorageService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/newSection/NewSectionController.js')
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'LIBRARY_ADMIN_MANAGE_PROJECT',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina profilo utente
                    .state('app.libraryApp.profile', {
                        url: '/profile',
                        views: {
                            "content": { templateUrl: 'app/routes/profile/profile.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/profile/ProfileController.js'
                                    ]);
                                }]
                        },
                        data: {
                            requiredAuthId: 'LIBRARY_VIEW_SELF_INFO'
                        }
                    })

                    // Definizione della pagina che crea una sezione da aggiungere a un Progetto
                    .state('app.libraryApp.editSection', {
                        url: '/editSection/:projectId/:sectionId',
                        views: {
                            "content": { templateUrl: 'app/routes/editSection/editSection.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load(['js/shared/previewCard/previewCard.js',
                                        'js/shared/itemActionsCard/itemActionsCard.js',
                                        'js/routes/editSection/EditSectionService.js',
                                        'js/routes/home/SessionStorageService.js'
                                    ])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/editSection/EditSectionController.js')
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'LIBRARY_ADMIN_MANAGE_PROJECT',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina che cerca oggetti da aggiungere alla sezione
                    .state('app.libraryApp.addItemToCurrentSection', {
                        url: '/addItemToCurrentSection/:projectId/:sectionId',
                        views: {
                            "content": { templateUrl: 'app/routes/addItemToCurrentSection/addItemToCurrentSection.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/addItemToCurrentSection/AddItemToCurrentSectionService.js',
                                        'js/routes/addItemToCurrentSection/SearchItemsToAddToSectionService.js',
                                        'js/routes/home/SessionStorageService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/addItemToCurrentSection/AddItemToCurrentSectionController.js')
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'LIBRARY_ADMIN_MANAGE_PROJECT',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina che cerca oggetti da aggiungere al Learning Plan
                    .state('app.libraryApp.addItemToCurrentLearningPlan', {
                        url: '/addItemToCurrentLearningPlan/:itemId/:isDraft',
                        views: {
                            "content": { templateUrl: 'app/routes/addItemToCurrentLearningPlan/addItemToCurrentLearningPlan.html' }
                        },
                        // Poichè il controller chiama subito una funzione del Service, è necessario caricare prima quest'ultimi
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/addItemToCurrentLearningPlan/AddItemToCurrentLearningPlanService.js',
                                        'js/routes/addItemToCurrentLearningPlan/SearchItemsToAddToLPService.js',
                                        'js/routes/home/SessionStorageService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/addItemToCurrentLearningPlan/AddItemToCurrentLearningPlanController.js')
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'LIBRARY_MANAGE_LEARNING_PLAN'
                        }
                    })

                    // Definizione della pagina la lista dei gruppi
                    .state('app.libraryApp.groupList', {
                        url: '/groupList',
                        views: {
                            "content": { templateUrl: 'app/routes/groupList/groupList.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/groupList/GroupListController.js',
                                        'js/routes/home/SessionStorageService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'LIBRARY_ADMIN_MANAGE_GROUPS',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina per la creazione di un nuovo gruppo
                    .state('app.libraryApp.newGroup', {
                        url: '/newGroup',
                        views: {
                            "content": { templateUrl: 'app/routes/newGroup/newGroup.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/newGroup/NewGroupController.js',
                                        'js/routes/home/SessionStorageService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'LIBRARY_ADMIN_MANAGE_GROUPS',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina per la creazione di un nuovo template
                    .state('app.libraryApp.newTemplate', {
                        url: '/newTemplate/:textTemplateType',
                        views: {
                            "content": { templateUrl: 'app/routes/newTemplate/newTemplate.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/newTemplate/NewTemplateController.js',
                                        'js/routes/newTemplate/NewTemplateService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'LIBRARY_MANAGE_TEMPLATE',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina per la modifica di un template
                    .state('app.libraryApp.editTemplate', {
                        url: '/editTemplate/:textTemplateId',
                        views: {
                            "content": { templateUrl: 'app/routes/editTemplate/editTemplate.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/editTemplate/EditTemplateController.js',
                                        'js/routes/editTemplate/EditTemplateService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'LIBRARY_MANAGE_TEMPLATE',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina contenente i dettagli di un gruppo selezionato
                    .state('app.libraryApp.groupDetail', {
                        url: '/groupDetail/:groupId',
                        views: {
                            "content": { templateUrl: 'app/routes/groupDetail/groupDetail.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/groupDetail/GroupDetailController.js',
                                        'js/routes/home/SessionStorageService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'LIBRARY_ADMIN_MANAGE_GROUPS',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina per la modifica di un gruppo
                    .state('app.libraryApp.editGroup', {
                        url: '/editGroup/:groupId',
                        views: {
                            "content": { templateUrl: 'app/routes/editGroup/editGroup.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/editGroup/EditGroupController.js',
                                        'js/routes/editGroup/EditGroupService.js',
                                        'js/routes/home/SessionStorageService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'LIBRARY_ADMIN_MANAGE_GROUPS',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina con il questionario per la certificazione
                    .state('app.libraryApp.survey', {
                        url: '/survey/:itemId',
                        views: {
                            "content": { templateUrl: 'app/routes/survey/survey.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/itemDetail/ItemDetailService.js',
                                        'js/routes/survey/SurveyController.js',
                                        'js/routes/home/SessionStorageService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'LIBRARY_USE_ITEM'
                        }
                    })

                    // Definizione della pagina post verifica
                    .state('app.libraryApp.surveyResult', {
                        url: '/surveyResult/:surveyId',
                        views: {
                            "content": { templateUrl: 'app/routes/surveyResult/surveyResult.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/surveyResult/SurveyResultController.js',
                                        'js/routes/home/SessionStorageService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'LIBRARY_USE_ITEM'
                        }
                    })

                    // Definizione della pagina per la creazione un Learning Plan
                    .state('app.libraryApp.newLearningPlan', {
                        url: '/newLearningPlan/:itemId',
                        views: {
                            "content": { templateUrl: 'app/routes/newLearningPlan/newLearningPlan.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load(['js/routes/newLearningPlan/NewLearningPlanService.js',
                                        'js/routes/home/SessionStorageService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/newLearningPlan/NewLearningPlanController.js')
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'LIBRARY_MANAGE_LEARNING_PLAN'
                        }
                    })

                    // Definizione della pagina per la creazione un Learning Plan
                    .state('app.libraryApp.newLearningPlanAdmin', {
                        url: '/newLearningPlanAdmin/:itemId',
                        views: {
                            "content": { templateUrl: 'app/routes/newLearningPlan/newLearningPlanAdmin.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load(['js/routes/newLearningPlan/NewLearningPlanService.js',
                                        'js/routes/home/SessionStorageService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/newLearningPlan/NewLearningPlanAdminController.js')
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'LIBRARY_MANAGE_LEARNING_PLAN'
                        }
                    })

                    // Definizione della pagina per la creazione di un nuovo progetto
                    .state('app.libraryApp.newProject', {
                        url: '/newProject',
                        views: {
                            "content": { templateUrl: 'app/routes/newProject/newProject.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/newProject/NewProjectService.js',
                                        'js/routes/home/SessionStorageService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/newProject/NewProjectController.js')
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'LIBRARY_ADMIN_MANAGE_PROJECT',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina per l'editing di un template mail di sistema
                    .state('app.libraryApp.editSystemMailTemplate', {
                        url: '/editSystemMailTemplate/:textTemplateId',
                        views: {
                            "content": { templateUrl: 'tpl/template/editSystemMailTemplate.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/controllers/template/EditSystemMailTemplateController.js',
                                        'js/services/template/TemplateService.js',
                                        'js/routes/home/SessionStorageService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'DEVPATH_VIEW_SELF_INFO'
                        }
                    })


                    // Definizione della pagina per l'editing di un template notifica di sistema
                    .state('app.libraryApp.editSystemNotificationTemplate', {
                        url: '/editSystemNotificationTemplate/:textTemplateId',
                        views: {
                            "content": { templateUrl: 'tpl/template/editSystemNotificationTemplate.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/controllers/template/EditSystemNotificationTemplateController.js',
                                        'js/services/template/TemplateService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'DEVPATH_VIEW_SELF_INFO'
                        }
                    })
                    //TODO_ REMOVE
                    // Definizione della pagina per l'editing di un template generico
                    .state('app.libraryApp.upload', {
                        url: '/upload',
                        views: {
                            "content": { templateUrl: 'tpl/upload/upload.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/controllers/upload/UploadController.js',
                                        'js/services/upload/UploadService.js',
                                        "js/directives/upload/thumb.js",
                                        'js/services/anag/AnagService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'DEVPATH_VIEW_SELF_INFO'
                        }
                    })

                    // Definizione della pagina impostazioni
                    .state('app.libraryApp.settings', {
                        url: '/settings',
                        views: {
                            "content": { templateUrl: 'tpl/settings/settings.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/controllers/settings/SettingsController.js',
                                        'js/routes/home/SessionStorageService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'DEVPATH_VIEW_SELF_INFO'
                        }
                    })

                    // Pagina di visione Item accessibile da sistemi esterni
                    .state('app.access.standaloneItemPlayer', {
                        url: '/standaloneItemPlayer/:itemId',
                        templateUrl: 'app/routes/standaloneItemPlayer/standaloneItemPlayer.html',
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/shared/kalturaPlayer/kalturaPlayer.js',
                                        'js/shared/scormPlayer/scormPlayer.js',
                                        'js/routes/itemDetail/ItemDetailService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/standaloneItemPlayer/StandaloneItemPlayerController.js')
                                        });
                                }]
                        }
                        /* ,
                        data: {
                            requiredAuthId: 'LIBRARY_USE_ITEM'
                        } */
                    })

                    .state("app.access.error", {
                        url: "/error/:errorCode",
                        templateUrl: "tpl/page_error.html",
                        resolve: {
                            deps: ["$ocLazyLoad",
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load(["js/controllers/ErrorCtrl.js"]);
                                }]
                        }
                    })
                    .state("app.access.403", {
                        url: "/403",
                        templateUrl: "app/routes/errors/page_403.html"
                    })
                    .state("app.access.404", {
                        url: "/404",
                        templateUrl: "app/routes/errors/page_404.html"
                    })
            }])

    // Definisco l'interceptor per iniettare nell'header il token e che identifica la risposta "necessario login"
    .factory('tokenInjectInterceptor', ($q: angular.IQService, $location: ng.ILocationService, GlobalApplicationData: any, LibraryApplicationData: any, $injector: ng.auto.IInjectorService, $sessionStorage: any) => {
        return {
            request: (config: any) => {
                config.headers = config.headers || {};
                // Mi salvo dove era richiesto andare (se non ne ho già uno e se è il primo giro)
                if (!$sessionStorage.originalRequestedURL && $location.path().indexOf("/login") === -1 &&
                    $location.path().indexOf("/localLogin") === -1 && $location.path().indexOf("/recoveryUserPassword") === -1 && $location.path().indexOf("/app/intermediate") === -1 && firstPageVisited) {
                    firstPageVisited = false;
                    $sessionStorage.originalRequestedURL = $location.path();
                    $sessionStorage.originalRequestedParams = JSON.stringify($location.search());
                }
                firstPageVisited = false;
                if ($sessionStorage.identityToken) {
                    config.headers.Authorization = 'Bearer ' + $sessionStorage.identityToken;
                    // Salvo nel session storage i parametri arrivati dalla glp, come il cluster, le aree funzionali ecc
                    let params = $location.search();
                    if (params) {
                        if (params.clusters && params.clusters.length) {
                            $sessionStorage.clusters = params.clusters.split(",");
                        }
                        if (params.searchedText && params.searchedText.length) {
                            $sessionStorage.searchedText = params.searchedText;
                        }
                        if (params.techCompetences && params.techCompetences.length) {
                            $sessionStorage.techCompetences = params.techCompetences.split(",");
                        }
                        if (params.softCompetences && params.softCompetences.length) {
                            $sessionStorage.softCompetences = params.softCompetences.split(",");
                        }
                        if (params.openedTab) {
                            $sessionStorage.openedTab = parseInt(params.openedTab, 10);
                        }
                        if (params.techSkillsOnly) {
                            $sessionStorage.techSkillsOnly = (params.techSkillsOnly === "true" || params.techSkillsOnly === true);
                        }
                        if (params.softSkillsOnly) {
                            $sessionStorage.softSkillsOnly = (params.softSkillsOnly === "true" || params.softSkillsOnly === true);
                        }
                        if (params.functionalAreas && params.functionalAreas.length) {
                            $sessionStorage.functionalAreas = params.functionalAreas.split(",");
                        }
                        if (params.levels && params.levels.length) {
                            $sessionStorage.levels = params.levels.split(",");
                        }
                        if (params.streamTags && params.streamTags.length) {
                            $sessionStorage.streamTags = params.streamTags.split(",");
                        }
                        if (params.argumentsTags && params.argumentsTags.length) {
                            $sessionStorage.argumentsTags = params.argumentsTags.split(",");
                        }
                    }
                }
                else {
                    // Dato che non ce l'ho, verifico se mi è arrivato un identity token in url, nel qual caso lo sostituisco all'eventuale esistente in session storage (e quindi poi anche in GlobalApplicationData)
                    let params = $location.search();
                    const localStorageToken = localStorage.getItem("token");
                    let skipSso;
                    if (params) {
                        if (localStorageToken) {
                            $sessionStorage.identityToken = localStorageToken;
                            localStorage.removeItem("token");
                        }
                        if (params.skipSso) {
                            skipSso = (params.skipSso === "true" || params.skipSso === true);
                        }
                        if (params.clusters && params.clusters.length) {
                            $sessionStorage.clusters = params.clusters.split(",");
                        }
                        if (params.searchedText && params.searchedText.length) {
                            $sessionStorage.searchedText = params.searchedText;
                        }
                        if (params.techCompetences && params.techCompetences.length) {
                            $sessionStorage.techCompetences = params.techCompetences.split(",");
                        }
                        if (params.softCompetences && params.softCompetences.length) {
                            $sessionStorage.softCompetences = params.softCompetences.split(",");
                        }
                        if (params.openedTab) {
                            $sessionStorage.openedTab = parseInt(params.openedTab, 10);
                        }
                        if (params.techSkillsOnly) {
                            $sessionStorage.techSkillsOnly = (params.techSkillsOnly === "true" || params.techSkillsOnly === true);
                        }
                        if (params.softSkillsOnly) {
                            $sessionStorage.softSkillsOnly = (params.softSkillsOnly === "true" || params.softSkillsOnly === true);
                        }
                        if (params.functionalAreas && params.functionalAreas.length) {
                            $sessionStorage.functionalAreas = params.functionalAreas.split(",");
                        }
                        if (params.levels && params.levels.length) {
                            $sessionStorage.levels = params.levels.split(",");
                        }
                        if (params.streamTags && params.streamTags.length) {
                            $sessionStorage.streamTags = params.streamTags.split(",");
                        }
                        if (params.argumentsTags && params.argumentsTags.length) {
                            $sessionStorage.argumentsTags = params.argumentsTags.split(",");
                        }
                    }
                    // Non ho i dati, quindi vado alla pagina che cerca o ricrea il token di autenticazione
                    // Ammesso che non stia già andando lì... (o stia caricando il main template che fa parte del "lì")
                    if (config.url && (config.url.indexOf("app/routes/login/login.html") === -1 && config.url.indexOf("app/routes/login/ssoLogin.html") === -1 && config.url.indexOf("app/shared/global/commonServices.html") === -1)) {
                        let $state: any = $injector.get("$state");
                        if (!$state.current || ($state.current.data && $state.current.data.requiredAuthId)) {
                            if (skipSso) {
                                $state.go("app.localLogin");
                            } else {
                                $state.go("app.login");
                            }
                        }
                    }
                }

                return config;
            },

            response: (response: any) => {
                // Le risposte dei servizi possono contenere una SenecaResponse che ha un codice di errore e la risposta
                // Nel caso il codice sia uno specifico (equivalente del 401 del responseError), allora butto fuori l'utente
                if (response && response.data && response.data.error &&
                    (response.data.error === LibraryApplicationData.constants.LOGGED_USER_NOT_FOUND || response.data.error === LibraryApplicationData.constants.USER_WITHOUT_AUTHS)) {
                    // La sessione utente è sicuramente scaduta, quindi cancello anche il token
                    $sessionStorage.identityToken = null;
                    GlobalApplicationData.jwtPayload = null;
                    GlobalApplicationData.showSessionExpiredAlert = true;
                    // Vado alla pagina che cerca o ricrea il token di autenticazione
                    //$location.path('#/app/authenticating');
                    var $state: any = $injector.get("$state");
                    $state.go("app.login");
                }
                return response;
            },

            responseError: (rejection: any) => {
                if (rejection.status == 401) {
                    // La sessione utente è sicuramente scaduta, quindi cancello anche il token
                    $sessionStorage.identityToken = null;
                    GlobalApplicationData.jwtPayload = null;
                    GlobalApplicationData.showSessionExpiredAlert = true;
                    // Vado alla pagina che cerca o ricrea il token di autenticazione
                    //$location.path('#/app/authenticating');
                    var $state: any = $injector.get("$state");
                    $state.go("app.login");
                }
                return $q.reject(rejection);
            }
        };
    })

    .config(['$httpProvider', ($httpProvider: angular.IHttpProvider) => {
        $httpProvider.interceptors.push('tokenInjectInterceptor');
    }])

    // Definisco il listener per il cambio di state (pagina) che controlla i diritti degli utenti
    .run(($rootScope: ng.IScope, $state: angular.ui.IStateService, UserService: any, GlobalApplicationData: any, $location: any, $sessionStorage: any) => {
        $rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams) => {
            if (toState.name == "app.login" && fromState.name) {
                $sessionStorage.originalRequestedURL = $location.path();
                $sessionStorage.originalRequestedParams = JSON.stringify($location.search());
            }
            // Se ho dei parametri impostati per i diritti di visibilità
            if (toState.data && toState.data.requiredAuthId) {
                // Verifico se ho già fatto un login o sto andando lì
                if (GlobalApplicationData.jwtPayload || toState.name === "app.localLogin") {
                    // Verifico se sono abilitato a vederli
                    if (!UserService.isUserAuthorized(toState.data.requiredAuthId)) {
                        event.preventDefault();
                        //return false;
                        $state.go('app.access.403');
                    }
                }
                else {
                    // Non ho ancora fatto login, quindi mando l'utente alla pagina dedicata
                    event.preventDefault();
                    $state.go("app.localLogin");
                }
            }

            // Tengo traccia del fatto che sono nella sezione di amministrazione
            if (toState.data && toState.data.forceAdminMode === true) {
                $sessionStorage.adminMode = true;
            } else if (toState.data && toState.data.forceAdminMode === false) {
                $sessionStorage.adminMode = false;
            }
        })
        $rootScope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
            if ($location.path().indexOf("/login") === -1 && $location.path().indexOf("/recoveryUserPassword") === -1 && $location.path().indexOf("/localLogin") === -1 && $location.path().indexOf("/app/intermediate") === -1) {
                $sessionStorage.originalRequestedURL = $location.path();
                $sessionStorage.originalRequestedParams = JSON.stringify($location.search());
            }
        })
    })